// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".E2VIYUcf7C2KsK8HSWHlw {\n  padding: 10px 0;\n  border-bottom: 1px solid var(--gallery, #ededed);\n  margin: 0 16px;\n}\n", "",{"version":3,"sources":["Subheader.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gDAAgD;EAChD,cAAc;AAChB","file":"Subheader.css","sourcesContent":[".subheader {\n  padding: 10px 0;\n  border-bottom: 1px solid var(--gallery, #ededed);\n  margin: 0 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"subheader": "E2VIYUcf7C2KsK8HSWHlw"
};
module.exports = exports;
