import { hot } from 'react-hot-loader/root';
import React from 'react';
import { useCustomDomainTrialLimitHandler } from '../../../hooks/useTrialLimitationFeatureGate';
import { useTrialPlanUpgradeHandler } from '../../../hooks/usePlanSelectionFeatureGate';
import styles from './CnameFooter.css';
interface CnameFooterProps {
  entriDomainEnabled: boolean;
  settingsPagesDomain: string;
  newDomainPath: string;
}

const CnameFooter = ({
  entriDomainEnabled,
  settingsPagesDomain,
  newDomainPath
}: CnameFooterProps) => {
  const urlToDomainGuide =
    'https://documentation.unbounce.com/hc/en-us/articles/203661044-Connecting-Your-Domain-to-Unbounce';

  const handleAddDomainClick = (e?: Event | React.SyntheticEvent) => {
    e?.preventDefault();
    const element = document.getElementById('hidden_wp_domain_trigger_footer');
    element?.click();
  };

  const gatedDomainClickHandler = useCustomDomainTrialLimitHandler(
    useTrialPlanUpgradeHandler(handleAddDomainClick)
  );

  return (
    <div className={styles.cnameFooter}>
      <a
        className="js-overlay-link"
        data-remote="true"
        id="hidden_wp_domain_trigger_footer"
        style={{ display: 'none' }}
        href={newDomainPath}
      />
      {entriDomainEnabled ? (
        <>
          <span>Need help setting up a domain? </span>
          <span>
            <a className={styles.link} target="_blank" href={urlToDomainGuide}>
              Follow our handy guide{' '}
            </a>
            or
            <a
              onClick={gatedDomainClickHandler}
              data-testid="cname-footer-add-domain"
              className={styles.link}
            >
              {' '}
              try connecting a domain manually
            </a>
          </span>
        </>
      ) : (
        <>
          <span>
            All Custom Domains should have a CNAME pointing to{' '}
            <strong>{settingsPagesDomain}</strong>.{' '}
          </span>
          <span>
            If you have any questions about setting up your Custom Domains, please try setting one
            up by{' '}
          </span>
          <a className={styles.link} target="_blank" href={urlToDomainGuide}>
            <strong>following our handy guide.</strong>
          </a>
        </>
      )}
    </div>
  );
};

export default hot(CnameFooter);
