// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3vE6qT1epdvu3wBsno5-yL {\n  background: $gallery;\n  display: grid;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0 20px;\n  grid-gap: 10px;\n  grid-auto-flow: column;\n}\n", "",{"version":3,"sources":["PageFooter.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,cAAc;EACd,sBAAsB;AACxB","file":"PageFooter.css","sourcesContent":[".footer {\n  background: $gallery;\n  display: grid;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 0 20px;\n  grid-gap: 10px;\n  grid-auto-flow: column;\n}\n"]}]);
// Exports
exports.locals = {
	"footer": "_3vE6qT1epdvu3wBsno5-yL"
};
module.exports = exports;
