import { GatedAccessEventProps, useGatedAccessEvent } from './useGatedAccessEvent';

export const usePlanSelectionFeatureGate = ({
  gatedFunction,
  customizations
}: Omit<GatedAccessEventProps, 'events'>) => {
  const gatedDomainUpgradeHandler = useGatedAccessEvent({
    gatedFunction,
    customizations,
    events: {
      REQUEST: 'PLAN_SELECTION_FEATURE_GATE_DISPLAY_REQUEST',
      PROCESSING: 'PLAN_SELECTION_FEATURE_GATE_DISPLAY_PROCESSING',
      RESPONSE: 'PLAN_SELECTION_FEATURE_GATE_DISPLAY_RESPONSE'
    }
  });

  return gatedDomainUpgradeHandler;
};

export const useTrialPlanUpgradeHandler = (gatedFunction: (...args: any[]) => any) => {
  const gatedSubscriptionPrompt = `Please upgrade to&nbsp<strong>Experiment</strong>&nbspor&nbsp<strong>Optimize</strong>&nbspfor higher user and domain limits!`;
  return usePlanSelectionFeatureGate({
    gatedFunction,
    customizations: {
      prompt: gatedSubscriptionPrompt
    }
  });
};
