// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2mAXEVu4hWpqe_oldXZF0C {\n  align-items: center;\n  background: $pampas;\n  display: flex;\n  padding: 20px;\n}\n\n.N04iKXtttAx4TU-YGwoVo {\n  color: $scorpion;\n  flex: 1 1;\n  margin: 0;\n}\n\n._2sYF2kJfijZa1jzOHcWC7u {\n  cursor: pointer;\n  display: flex;\n  height: 15px;\n  padding: 4px;\n  width: 15px;\n}\n\n._2sYF2kJfijZa1jzOHcWC7u path {\n  fill: $silver;\n  pointer-events: none;\n  transition: fill 0.2s ease-in;\n}\n\n._2sYF2kJfijZa1jzOHcWC7u:hover path {\n  fill: $silverChalice;\n}\n\n._2sYF2kJfijZa1jzOHcWC7u rect {\n  fill: transparent;\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["ModalHeader.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,SAAS;AACX;;AAEA;EACE,eAAe;EACf,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","file":"ModalHeader.css","sourcesContent":[".header {\n  align-items: center;\n  background: $pampas;\n  display: flex;\n  padding: 20px;\n}\n\n.title {\n  color: $scorpion;\n  flex: 1 1;\n  margin: 0;\n}\n\n.close {\n  cursor: pointer;\n  display: flex;\n  height: 15px;\n  padding: 4px;\n  width: 15px;\n}\n\n.close path {\n  fill: $silver;\n  pointer-events: none;\n  transition: fill 0.2s ease-in;\n}\n\n.close:hover path {\n  fill: $silverChalice;\n}\n\n.close rect {\n  fill: transparent;\n  pointer-events: none;\n}\n"]}]);
// Exports
exports.locals = {
	"header": "_2mAXEVu4hWpqe_oldXZF0C",
	"title": "N04iKXtttAx4TU-YGwoVo",
	"close": "_2sYF2kJfijZa1jzOHcWC7u"
};
module.exports = exports;
