// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".x0WL4hOB2IbyWHGgzL84Q,\n._295ACG0dHUHoi86FlmqrcJ {\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-template-areas: \"area1 area2\";\n  grid-column-gap: 8px;\n  column-gap: 8px;\n}\n\n.x0WL4hOB2IbyWHGgzL84Q [class^='loadingIcon'],\n._295ACG0dHUHoi86FlmqrcJ ._3IhaIsZzXcTAPzd8795szA {\n  grid-area: area1;\n  align-self: center;\n  justify-self: center;\n}\n\n.x0WL4hOB2IbyWHGgzL84Q [class^='loadingIcon'] {\n  height: 26px !important;\n  width: 26px !important;\n}\n\n.x0WL4hOB2IbyWHGgzL84Q span,\n._295ACG0dHUHoi86FlmqrcJ a[class^='button'] {\n  grid-area: area2;\n  align-self: center;\n  justify-self: center;\n}\n", "",{"version":3,"sources":["DomainStatus.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,gCAAgC;EAChC,kCAAkC;EAClC,oBAAe;EAAf,eAAe;AACjB;;AAEA;;EAEE,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;;EAEE,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;AACtB","file":"DomainStatus.css","sourcesContent":[".wrapperLoading,\n.wrapperStatus {\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-template-areas: \"area1 area2\";\n  column-gap: 8px;\n}\n\n.wrapperLoading [class^='loadingIcon'],\n.wrapperStatus .tooltip {\n  grid-area: area1;\n  align-self: center;\n  justify-self: center;\n}\n\n.wrapperLoading [class^='loadingIcon'] {\n  height: 26px !important;\n  width: 26px !important;\n}\n\n.wrapperLoading span,\n.wrapperStatus a[class^='button'] {\n  grid-area: area2;\n  align-self: center;\n  justify-self: center;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapperLoading": "x0WL4hOB2IbyWHGgzL84Q",
	"wrapperStatus": "_295ACG0dHUHoi86FlmqrcJ",
	"tooltip": "_3IhaIsZzXcTAPzd8795szA"
};
module.exports = exports;
