// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".oS59_g7EIlIxg5Z1l8ZAI {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 2;\n}\n", "",{"version":3,"sources":["portal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,UAAU;AACZ","file":"portal.css","sourcesContent":[".portal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 2;\n}\n"]}]);
// Exports
exports.locals = {
	"portal": "oS59_g7EIlIxg5Z1l8ZAI"
};
module.exports = exports;
