// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._10wMrcldt47Px4vZCvqHJe {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n  align-items: center;\n  justify-content: flex-start;\n  font-weight: $fontWeight;\n}\n\n._5gRa-l8R_8v-ery5Gj1AJ,\n._1299OPu6JgA0xfVqG8DFtL {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["DoNotShow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;AAC1B;;AAEA;;EAEE,eAAe;AACjB","file":"DoNotShow.css","sourcesContent":[".wrapper {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n  align-items: center;\n  justify-content: flex-start;\n  font-weight: $fontWeight;\n}\n\n.input,\n.label {\n  cursor: pointer;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "_10wMrcldt47Px4vZCvqHJe",
	"input": "_5gRa-l8R_8v-ery5Gj1AJ",
	"label": "_1299OPu6JgA0xfVqG8DFtL"
};
module.exports = exports;
