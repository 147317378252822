// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".s6jQ3SQ4Be_yA4EnFm3W_ {\n  color: #808080;\n\n  .rRSpIoJpr9po8lmrBumoo {\n    cursor: pointer;\n    text-decoration: none;\n  }\n\n  .rRSpIoJpr9po8lmrBumoo:hover {\n    text-decoration: underline;\n  }\n}\n", "",{"version":3,"sources":["CnameFooter.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd;IACE,eAAe;IACf,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;AACF","file":"CnameFooter.css","sourcesContent":[".cnameFooter {\n  color: #808080;\n\n  .link {\n    cursor: pointer;\n    text-decoration: none;\n  }\n\n  .link:hover {\n    text-decoration: underline;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"cnameFooter": "s6jQ3SQ4Be_yA4EnFm3W_",
	"link": "rRSpIoJpr9po8lmrBumoo"
};
module.exports = exports;
