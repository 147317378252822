// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GVtkwMJd_eSWBzDr7D38n {\n  position: relative;\n  margin: 10px 0 40px;\n}\n", "",{"version":3,"sources":["EntriDomainInputModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","file":"EntriDomainInputModal.css","sourcesContent":[".entriInputWrapper {\n  position: relative;\n  margin: 10px 0 40px;\n}\n"]}]);
// Exports
exports.locals = {
	"entriInputWrapper": "GVtkwMJd_eSWBzDr7D38n"
};
module.exports = exports;
