// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._27wykvSsCe8-D5zNEvElZi {\n  align-items: center;\n  background-color: $pampas;\n  display: flex;\n  min-height: 32px;\n  padding: 10px 20px;\n}\n\n.meoKLYn81xUf9yCh2162v {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n  justify-content: flex-end;\n}\n\n._2Yb7_2_CANKePbxjIorGzX {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n  justify-content: flex-end;\n  grid-template-columns: 1fr;\n}\n\n._3MLgej6Ayn4QHALdHj_DXd {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n}\n", "",{"version":3,"sources":["ModalFooter.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB","file":"ModalFooter.css","sourcesContent":[".footer {\n  align-items: center;\n  background-color: $pampas;\n  display: flex;\n  min-height: 32px;\n  padding: 10px 20px;\n}\n\n.right {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n  justify-content: flex-end;\n}\n\n.rightWithLeftElm {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n  justify-content: flex-end;\n  grid-template-columns: 1fr;\n}\n\n.left {\n  display: grid;\n  grid-auto-flow: column;\n  grid-gap: 10px;\n}\n"]}]);
// Exports
exports.locals = {
	"footer": "_27wykvSsCe8-D5zNEvElZi",
	"right": "meoKLYn81xUf9yCh2162v",
	"rightWithLeftElm": "_2Yb7_2_CANKePbxjIorGzX",
	"left": "_3MLgej6Ayn4QHALdHj_DXd"
};
module.exports = exports;
