// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".QUKfkwu6etTE_BZ3Z4cEy {\n  align-items: center;\n  background: $white;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 1;\n}\n\n._1gRqP6OCsAvNdq16aSvsAw {\n  color: $emperor;\n  font-size: $fontSizeBase;\n  font-weight: $fontWeight;\n  line-height: 1.5;\n  padding: 20px;\n  text-align: center;\n}\n\n.qJ6JSDmZcceYWY2t60WYH {\n  color: $emperor;\n  font-size: $fontSizeXs;\n  line-height: 1.5;\n  max-width: 367px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["Loader.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,OAAO;EACP,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,wBAAwB;EACxB,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;AACpB","file":"Loader.css","sourcesContent":[".loader {\n  align-items: center;\n  background: $white;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 1;\n}\n\n.title {\n  color: $emperor;\n  font-size: $fontSizeBase;\n  font-weight: $fontWeight;\n  line-height: 1.5;\n  padding: 20px;\n  text-align: center;\n}\n\n.description {\n  color: $emperor;\n  font-size: $fontSizeXs;\n  line-height: 1.5;\n  max-width: 367px;\n  text-align: center;\n}\n"]}]);
// Exports
exports.locals = {
	"loader": "QUKfkwu6etTE_BZ3Z4cEy",
	"title": "_1gRqP6OCsAvNdq16aSvsAw",
	"description": "qJ6JSDmZcceYWY2t60WYH"
};
module.exports = exports;
