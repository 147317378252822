import { GatedAccessEventProps, useGatedAccessEvent } from './useGatedAccessEvent';

export const useTrialLimitationFeatureGate = ({
  gatedFunction,
  customizations
}: Omit<GatedAccessEventProps, 'events'>) => {
  const gatedDomainUpgradeHandler = useGatedAccessEvent({
    gatedFunction,
    customizations,
    events: {
      REQUEST: 'FEATURE_LIMITATION_GATE_DISPLAY_REQUEST',
      PROCESSING: 'FEATURE_LIMITATION_GATE_DISPLAY_PROCESSING',
      RESPONSE: 'FEATURE_LIMITATION_GATE_DISPLAY_RESPONSE'
    }
  });

  return gatedDomainUpgradeHandler;
};

export const useCustomDomainTrialLimitHandler = (gatedFunction: (...args: any[]) => void) => {
  const gatedSubscriptionPrompt = `<p style="float: left; text-decoration: none; text-align: center; display: grid; font-family: 'Barlow Semi Condensed'; margin-bottom: 20px;">
    <strong>✔ Upgrade to a paid plan to connect your custom domain today.</strong><br>
    <span style="margin-top: 13px;">Need more time? Your trial lets you publish pages on a free domain, no strings attached.</span>
  </p>`;
  return useTrialLimitationFeatureGate({
    gatedFunction,
    customizations: {
      prompt: gatedSubscriptionPrompt
    }
  });
};
