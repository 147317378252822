// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SJGc1jXr6Zjg9lUDU_O8u {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: 352px;\n  width: 100%;\n}\n\n._22tXNHewr9SB5UmAxvB4OO,\n._1dd3-GcJBbmsAg1Q1EDYik {\n  color: $emperor;\n  letter-spacing: -0.1px;\n  max-width: 320px;\n}\n\n._22tXNHewr9SB5UmAxvB4OO {\n  font-size: $fontSizeMdLg;\n  font-weight: $fontWeight;\n  line-height: 1.18;\n  margin-bottom: 22px;\n  width: 100%;\n}\n\n._1dd3-GcJBbmsAg1Q1EDYik {\n  font-size: $fontSizeSm;\n  line-height: 1.69;\n}\n", "",{"version":3,"sources":["Add.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;AACb;;AAEA;;EAEE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,wBAAwB;EACxB,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB","file":"Add.css","sourcesContent":[".content {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: 352px;\n  width: 100%;\n}\n\n.title,\n.description {\n  color: $emperor;\n  letter-spacing: -0.1px;\n  max-width: 320px;\n}\n\n.title {\n  font-size: $fontSizeMdLg;\n  font-weight: $fontWeight;\n  line-height: 1.18;\n  margin-bottom: 22px;\n  width: 100%;\n}\n\n.description {\n  font-size: $fontSizeSm;\n  line-height: 1.69;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "SJGc1jXr6Zjg9lUDU_O8u",
	"title": "_22tXNHewr9SB5UmAxvB4OO",
	"description": "_1dd3-GcJBbmsAg1Q1EDYik"
};
module.exports = exports;
