import React from 'react';

export interface FeatureGateEvents {
  REQUEST: string;
  PROCESSING: string;
  RESPONSE: string;
}

interface Customizations {
  prompt: string;
}

type GatedFeatureEvent = CustomEvent<{
  isAccountRestricted?: boolean;
  gatedFn: (e?: Event) => void | Promise<void>;
}>;

export interface GatedAccessEventProps {
  events: FeatureGateEvents;
  gatedFunction: (e?: Event | React.SyntheticEvent) => void | Promise<void>;
  customizations?: Customizations;
}

const buildResponseEventHandler = (gatedFunction: (e?: Event) => void) => (event: Event) => {
  const gatedEvent = event as GatedFeatureEvent;
  if (!gatedEvent.detail.isAccountRestricted) {
    gatedFunction(gatedEvent);
  }
};

const buildRequestEvent = (events: FeatureGateEvents, customizations?: Customizations) =>
  new CustomEvent(events.REQUEST, {
    detail: {
      customizations
    }
  });

export const gatedAccessEvent =
  ({ events, gatedFunction, customizations }: GatedAccessEventProps) =>
  (event?: Event | React.SyntheticEvent) => {
    const TIMEOUT_INTERVAL = 250;
    const handleResponseEvent = buildResponseEventHandler(gatedFunction);
    const clearTimer = () => {
      clearTimeout(timer);
      document.removeEventListener(events.PROCESSING, clearTimer);
    };

    const timer = setTimeout(() => {
      gatedFunction(event);
    }, TIMEOUT_INTERVAL);

    document.addEventListener(events.PROCESSING, clearTimer);
    document.addEventListener(events.RESPONSE, handleResponseEvent);

    document.dispatchEvent(buildRequestEvent(events, customizations));

    return {
      cleanup: () => {
        document.removeEventListener(events.RESPONSE, handleResponseEvent);
        clearTimer();
      }
    };
  };

export const useGatedAccessEvent = ({
  events,
  gatedFunction,
  customizations
}: GatedAccessEventProps) => {
  const cleanupRef = React.useRef<(e?: Event | React.SyntheticEvent) => void>();
  const gatedFunctionRef = React.useRef(gatedFunction);

  React.useEffect(() => {
    gatedFunctionRef.current = gatedFunction;
  }, [gatedFunction]);

  React.useEffect(() => () => cleanupRef.current?.(), []);

  const gatedAccessWrapper = (e?: Event | React.SyntheticEvent) => {
    cleanupRef.current?.();
    const { cleanup } = gatedAccessEvent({
      gatedFunction: gatedFunctionRef.current,
      customizations,
      events
    })(e);
    cleanupRef.current = cleanup;
  };

  return gatedAccessWrapper;
};
