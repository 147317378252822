import React from 'react';
import { useCustomDomainTrialLimitHandler } from '../../../hooks/useTrialLimitationFeatureGate';

export type Props = {
  newDomainPath: string;
  onClickConnectDomain: () => void;
};

const EntriNoDomainsView = ({ newDomainPath, onClickConnectDomain }: Props) => {
  const newWordpressDomainPath = `${newDomainPath}?domain_type=wordpress`;
  const newManualDomainPath = `${newDomainPath}?domain_type=manual_domain`;

  const handleManualDomainClick = () => {
    let element: HTMLElement = document.getElementById(
      'manual_domain_link'
    ) as unknown as HTMLElement;
    element.click();
  };

  const handleWordpressDomainClick = () => {
    let element: HTMLElement = document.getElementById(
      'entri_wordpress_empty'
    ) as unknown as HTMLElement;
    element.click();
  };

  const gatedConnectDomainHandler = useCustomDomainTrialLimitHandler(onClickConnectDomain);
  const gatedConnectWordpressHandler = useCustomDomainTrialLimitHandler(handleWordpressDomainClick);
  const gatedConnectManualHandler = useCustomDomainTrialLimitHandler(handleManualDomainClick);

  return (
    <div className="empty-list-with-actions">
      <a
        className="js-overlay-link"
        data-remote="true"
        id="entri_wordpress_empty"
        style={{ display: 'none' }}
        href={newWordpressDomainPath}
      />
      <a
        className="js-overlay-link"
        data-remote="true"
        id="manual_domain_link"
        style={{ display: 'none' }}
        href={newManualDomainPath}
      />
      <div className="entri-empty-domain">
        <h2>Connect your domain</h2>

        <p className="blank-slate-desc">
          You can publish landing pages with custom URLs once you've connected a domain.
        </p>

        <p>
          <a
            className="btn large secondary btn-margin"
            data-testid="connect_wp_domain"
            onClick={gatedConnectWordpressHandler}
          >
            Connect a Wordpress Domain
          </a>
          <a
            className="btn large blue"
            data-testid="connect_domain"
            onClick={gatedConnectDomainHandler}
          >
            Connect a Domain
          </a>
        </p>

        <div className="entri-docs-links">
          <a
            href="https://documentation.unbounce.com/hc/en-us/articles/360001292583"
            rel="noreferrer"
            target="_blank"
          >
            How to Connect your Domain
          </a>
          <a href="#" onClick={gatedConnectManualHandler}>
            Manual Domain Connection
          </a>
          <a href="https://try.unbounce.com/purchase-domain" rel="noreferrer" target="_blank">
            Purchase a Domain
          </a>
        </div>
      </div>
    </div>
  );
};

export default EntriNoDomainsView;
