// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._10rNg7qIZ-YTeOlHNqQ4L2 {\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: 12px auto auto;\n  align-items: center;\n}\n\n._10rNg7qIZ-YTeOlHNqQ4L2 label {\n  margin: 0;\n  font-size: $fontSizeXs;\n  font-weight: $fontWeightBold;\n  color: $pampas;\n}\n\n._3SZRU7xC-qAfpakF1CPOlW {\n  padding: 10px 0;\n  font-size: $fontSizeXs;\n  color: $alto;\n}\n\n._2QWRxAyk9u040oIznlaV3- {\n  margin-left: auto;\n}\n\n._3f6ELQiPyYvPxnhle1mQle {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  background: $shamrock;\n}\n\n._2e6Y3KcfixgG53QrNCNtLC {\n  background: $radicalRed;\n}\n\n._2doDIyMvH26KXqMaEcOJLi {\n  background: $orange;\n}\n", "",{"version":3,"sources":["Counter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,qCAAqC;EACrC,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,sBAAsB;EACtB,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB","file":"Counter.css","sourcesContent":[".wrapper {\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: 12px auto auto;\n  align-items: center;\n}\n\n.wrapper label {\n  margin: 0;\n  font-size: $fontSizeXs;\n  font-weight: $fontWeightBold;\n  color: $pampas;\n}\n\n.info {\n  padding: 10px 0;\n  font-size: $fontSizeXs;\n  color: $alto;\n}\n\n.upgradeButton {\n  margin-left: auto;\n}\n\n.badge {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  background: $shamrock;\n}\n\n.full {\n  background: $radicalRed;\n}\n\n.nearly-full {\n  background: $orange;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "_10rNg7qIZ-YTeOlHNqQ4L2",
	"info": "_3SZRU7xC-qAfpakF1CPOlW",
	"upgradeButton": "_2QWRxAyk9u040oIznlaV3-",
	"badge": "_3f6ELQiPyYvPxnhle1mQle",
	"full": "_2e6Y3KcfixgG53QrNCNtLC",
	"nearly-full": "_2doDIyMvH26KXqMaEcOJLi"
};
module.exports = exports;
