// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3YUU5xKT8Ow5KHhAg4iHQw {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.OiU4BiCSn4LPJMFFjmFm- {\n  width: 400px;\n}\n\n.bwQKMiftUhbUReUX4XD4D {\n  display: grid;\n  grid-gap: 10px;\n  grid-auto-flow: column;\n}\n\n._8vRwt7oJ8Kvd7UH5Wou_h {\n  margin: 1em 0;\n}\n", "",{"version":3,"sources":["Subscriptions.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf","file":"Subscriptions.css","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.content {\n  width: 400px;\n}\n\n.actions {\n  display: grid;\n  grid-gap: 10px;\n  grid-auto-flow: column;\n}\n\n.description {\n  margin: 1em 0;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "_3YUU5xKT8Ow5KHhAg4iHQw",
	"content": "OiU4BiCSn4LPJMFFjmFm-",
	"actions": "bwQKMiftUhbUReUX4XD4D",
	"description": "_8vRwt7oJ8Kvd7UH5Wou_h"
};
module.exports = exports;
