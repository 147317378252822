// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3SIqSbOn-AW2Lararec33j {\n  background: $white;\n  border-radius: $borderRadiusMd;\n  margin: auto;\n  outline: none;\n  overflow: hidden;\n  padding: 0;\n  visibility: visible;\n  width: 630px;\n}\n\n.YKorFwYsUDT0fKD4pBa51 {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n  position: relative;\n  width: 100%;\n}\n", "",{"version":3,"sources":["Modal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,UAAU;EACV,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb","file":"Modal.css","sourcesContent":[".modal {\n  background: $white;\n  border-radius: $borderRadiusMd;\n  margin: auto;\n  outline: none;\n  overflow: hidden;\n  padding: 0;\n  visibility: visible;\n  width: 630px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n  position: relative;\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"modal": "_3SIqSbOn-AW2Lararec33j",
	"content": "YKorFwYsUDT0fKD4pBa51"
};
module.exports = exports;
