// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3pNwMKMx8pZbPaZSWNHob- {\n  height: 41px;\n  width: 41px;\n  grid-area: icon;\n}\n\n._3pNwMKMx8pZbPaZSWNHob- path {\n  fill: $emperor;\n}\n\n.W3g4l634ayZybT3EpuXJx {\n  grid-area: description;\n}\n\n.mZLqGWXRbDBa1Ioc0oqpT {\n  padding: 20px;\n  display: grid;\n  grid-gap: 20px;\n  grid-template-areas: \"icon description\";\n}\n", "",{"version":3,"sources":["RetryModalContent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,cAAc;EACd,uCAAuC;AACzC","file":"RetryModalContent.css","sourcesContent":[".icon {\n  height: 41px;\n  width: 41px;\n  grid-area: icon;\n}\n\n.icon path {\n  fill: $emperor;\n}\n\n.text {\n  grid-area: description;\n}\n\n.wrapper {\n  padding: 20px;\n  display: grid;\n  grid-gap: 20px;\n  grid-template-areas: \"icon description\";\n}\n"]}]);
// Exports
exports.locals = {
	"icon": "_3pNwMKMx8pZbPaZSWNHob-",
	"text": "W3g4l634ayZybT3EpuXJx",
	"wrapper": "mZLqGWXRbDBa1Ioc0oqpT"
};
module.exports = exports;
