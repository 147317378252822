// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._29vZfT0VMSspLdtt4uelkI {\n  align-items: center;\n  background: $unbounceBlue;\n  border-radius: 85px;\n  box-sizing: border-box;\n  display: flex;\n  height: 85px;\n  justify-content: center;\n  width: 85px;\n}\n\n.umSVQE0Z9LvgdFp2Vf5yy {\n  width: 40%;\n}\n\n.umSVQE0Z9LvgdFp2Vf5yy path {\n  fill: $white;\n}\n\n._2sUiO4U6dVV2MGRD1h0Vqs {\n  background: $linkWater;\n}\n\n._2sUiO4U6dVV2MGRD1h0Vqs path {\n  fill: $kleinBlue;\n}\n\n._25zAq_tFrr1iSxLQ0jSutO {\n  background: transparent;\n  border: 1px solid $unbounceBlue;\n}\n\n._25zAq_tFrr1iSxLQ0jSutO .umSVQE0Z9LvgdFp2Vf5yy path {\n  fill: $unbounceBlue;\n}\n\n._28Yn3aLJfHHXrhxWRX5X2v {\n  height: 5vw;\n  max-height: 80px;\n  max-width: 80px;\n  width: 5vw;\n}\n", "",{"version":3,"sources":["IconDisc.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,UAAU;AACZ","file":"IconDisc.css","sourcesContent":[".disc {\n  align-items: center;\n  background: $unbounceBlue;\n  border-radius: 85px;\n  box-sizing: border-box;\n  display: flex;\n  height: 85px;\n  justify-content: center;\n  width: 85px;\n}\n\n.icon {\n  width: 40%;\n}\n\n.icon path {\n  fill: $white;\n}\n\n.soft {\n  background: $linkWater;\n}\n\n.soft path {\n  fill: $kleinBlue;\n}\n\n.ghost {\n  background: transparent;\n  border: 1px solid $unbounceBlue;\n}\n\n.ghost .icon path {\n  fill: $unbounceBlue;\n}\n\n.responsive {\n  height: 5vw;\n  max-height: 80px;\n  max-width: 80px;\n  width: 5vw;\n}\n"]}]);
// Exports
exports.locals = {
	"disc": "_29vZfT0VMSspLdtt4uelkI",
	"icon": "umSVQE0Z9LvgdFp2Vf5yy",
	"soft": "_2sUiO4U6dVV2MGRD1h0Vqs",
	"ghost": "_25zAq_tFrr1iSxLQ0jSutO",
	"responsive": "_28Yn3aLJfHHXrhxWRX5X2v"
};
module.exports = exports;
