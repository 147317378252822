// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3nJfseCEIr3J8tlhGDmW1- {\n  margin-right: auto;\n}\n\n._1Ex-FWf2F8pOu8RueRbytq {\n  display: grid;\n  grid-auto-flow: column;\n  grid-template-columns: auto 250px;\n  grid-gap: 20px;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n@media ( max-width: 800px ) {\n  ._1Ex-FWf2F8pOu8RueRbytq {\n    grid-template-columns: 100%;\n    grid-auto-flow: row;\n    grid-gap: 10px;\n  }\n\n  ._1bEzaHjwfDbHt6_1chXwGq {\n    grid-row: 1;\n  }\n}\n", "",{"version":3,"sources":["Domains.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;EACjC,cAAc;EACd,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE;IACE,2BAA2B;IAC3B,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,WAAW;EACb;AACF","file":"Domains.css","sourcesContent":[".label {\n  margin-right: auto;\n}\n\n.subheader {\n  display: grid;\n  grid-auto-flow: column;\n  grid-template-columns: auto 250px;\n  grid-gap: 20px;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n@media ( max-width: 800px ) {\n  .subheader {\n    grid-template-columns: 100%;\n    grid-auto-flow: row;\n    grid-gap: 10px;\n  }\n\n  .searchbar {\n    grid-row: 1;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"label": "_3nJfseCEIr3J8tlhGDmW1-",
	"subheader": "_1Ex-FWf2F8pOu8RueRbytq",
	"searchbar": "_1bEzaHjwfDbHt6_1chXwGq"
};
module.exports = exports;
