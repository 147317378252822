import { hot } from 'react-hot-loader/root';
import React, { useState } from 'react';
import Header from './Header';
import HeaderSearch from './HeaderSearch';
import Subheader from './Subheader';
import EntriNoDomainsView from '../../Views/Domains/EntriNoDomainsView';
import EntriDomainInputModal from '../../Views/Domains/EntriDomainInputModal';
import { Button, ButtonLink } from '@unbounce/ui-components';
import styles from './Domains.css';
import { useTrialPlanUpgradeHandler } from '../../../hooks/usePlanSelectionFeatureGate';
import { useCustomDomainTrialLimitHandler } from '../../../hooks/useTrialLimitationFeatureGate';

declare global {
  interface Window {
    entri: any;
    config: any;
  }
}

export interface DomainsProps {
  title: string;
  query: string;
  newDomainPath: string;
  reachedLimit: boolean;
  toggle: string;
  emptyState: boolean;
  entriExperimentEnabled: boolean;
  rootDomainPlan: boolean;
}

const urlSearchParam = (() => {
  const queryPairs = window.location.search.replace(/^\?/, '').split('&');
  const searchPair = queryPairs.find((str) => str.startsWith('search='));

  return searchPair?.split('=')[1];
})();

const Domains = ({
  title,
  query,
  newDomainPath,
  reachedLimit,
  toggle,
  emptyState,
  entriExperimentEnabled,
  rootDomainPlan
}: DomainsProps) => {
  const [showEntriDomainInput, setShowEntriDomainInput] = useState(false);
  const entriInputModalFragment = showEntriDomainInput ? (
    <EntriDomainInputModal onClose={() => setShowEntriDomainInput(false)} />
  ) : null;

  if (entriExperimentEnabled && emptyState && !urlSearchParam) {
    return (
      <>
        <EntriNoDomainsView
          newDomainPath={newDomainPath}
          onClickConnectDomain={() => setShowEntriDomainInput(true)}
        />
        {entriInputModalFragment}
      </>
    );
  }

  const handleAddWordpressDomainClick = (e?: Event | React.SyntheticEvent | undefined) => {
    e?.preventDefault();
    let element: HTMLElement = document.getElementById(
      'hidden_wp_domain_trigger'
    ) as unknown as HTMLElement;
    element.click();
    window.dispatchEvent(
      new CustomEvent('UnbounceUITrackingEvent', {
        detail: {
          event_type: 'domains_connect_wp_domain_click',
          workflow: 'domains'
        }
      })
    );
  };

  const handleAddDomainClick = () => {
    reachedLimit && !rootDomainPlan
      ? window.dispatchEvent(
          new CustomEvent('UPDATE_UI.app-chrome.PROMPT_UPGRADE', {
            detail: {
              pluralFeatureName: 'custom domains'
            }
          })
        )
      : entriExperimentEnabled && setShowEntriDomainInput(true);
  };

  const gatedDomainUpgradeHandler = useCustomDomainTrialLimitHandler(
    useTrialPlanUpgradeHandler(handleAddDomainClick)
  );

  const gatedWordPressDomainUpgradeHandler = useCustomDomainTrialLimitHandler(
    useTrialPlanUpgradeHandler(handleAddWordpressDomainClick)
  );

  return (
    <>
      <a
        className="js-overlay-link"
        data-remote="true"
        id="hidden_wp_domain_trigger"
        style={{ display: 'none' }}
        href={`${newDomainPath}?domain_type=wordpress`}
      />
      <Header
        title={
          <>
            {title}
            {query ? <strong> {query}</strong> : ''}
          </>
        }
      >
        {reachedLimit && !rootDomainPlan ? (
          <Button data-testid="add-new-domain" onClick={gatedDomainUpgradeHandler}>
            Add a Domain
          </Button>
        ) : entriExperimentEnabled ? (
          <>
            <Button
              data-testid="add-new-wordpress-domain"
              data-remote="true"
              className="js-overlay-link"
              secondary={true}
              data-kmq={`["record", "CreateNewWordpressDomain.ButtonClicked", {"location": "app.domains"}]`}
              onClick={gatedWordPressDomainUpgradeHandler}
            >
              Connect a Wordpress Domain
            </Button>
            <Button onClick={gatedDomainUpgradeHandler}>Connect a Custom Domain</Button>
          </>
        ) : (
          <ButtonLink
            href={newDomainPath}
            data-testid="add-new-domain"
            data-remote="true"
            className="js-overlay-link"
            data-kmq={`["record", "CreateNew.ButtonClicked", {"location": "app.domains"}]`}
          >
            Add a Domain
          </ButtonLink>
        )}
      </Header>
      <Subheader className={styles.subheader}>
        <div dangerouslySetInnerHTML={{ __html: toggle }} />
        <HeaderSearch urlSearchParam={urlSearchParam} styles={styles.searchbar} />
      </Subheader>

      {entriInputModalFragment}
    </>
  );
};

export default hot(Domains);
