// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3fZ-c4llT0kejofUZqRcj6 {\n  position: fixed;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.75);\n  width: 100%;\n  height: 100%;\n  z-index: 50;\n}\n", "",{"version":3,"sources":["FullScreenLoader.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,WAAW;AACb","file":"FullScreenLoader.css","sourcesContent":[".wrap {\n  position: fixed;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.75);\n  width: 100%;\n  height: 100%;\n  z-index: 50;\n}\n"]}]);
// Exports
exports.locals = {
	"wrap": "_3fZ-c4llT0kejofUZqRcj6"
};
module.exports = exports;
