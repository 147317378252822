// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3ZR15W-LPVQhYkKFsmAhp2 {\n  cursor: pointer;\n  padding: 5px 10px;\n  background: $scorpion;\n  border-radius: $borderRadiusMd;\n  color: $silver !important;\n  font-size: $fontSizeSm;\n  line-height: 1.27;\n  font-weight: $fontWeightBold;\n}\n", "",{"version":3,"sources":["UpgradeButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,8BAA8B;EAC9B,yBAAyB;EACzB,sBAAsB;EACtB,iBAAiB;EACjB,4BAA4B;AAC9B","file":"UpgradeButton.css","sourcesContent":[".link {\n  cursor: pointer;\n  padding: 5px 10px;\n  background: $scorpion;\n  border-radius: $borderRadiusMd;\n  color: $silver !important;\n  font-size: $fontSizeSm;\n  line-height: 1.27;\n  font-weight: $fontWeightBold;\n}\n"]}]);
// Exports
exports.locals = {
	"link": "_3ZR15W-LPVQhYkKFsmAhp2"
};
module.exports = exports;
